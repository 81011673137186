.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	form {
		width: 343px;
	}
}

.headerContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.title {
	text-align: center;
	font-size: 34px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px; /* 117.647% */
	letter-spacing: 0.25px;
}

.description {
	/* text/body-1 */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: 0.5px;
}