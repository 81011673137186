.header {
	grid-area: header;
}

.root {

	display: grid;
	grid-template-columns: auto 1fr 1fr;
	grid-template-rows: 64px 1fr 100px;
	min-height: 100vh;
	min-width: 100vw;
	padding: 0;
	grid-template-areas:
		'header header header'
		'nav main main'
		'footer footer footer';
}

.main {
	grid-area: main;
	overflow: hidden;
	display: flex;
	flex: 1;
	width: '100%';
	flex-direction: column;
	padding: 1rem 1rem;
}

.nav {
	grid-area: nav;
	position: relative;
}
.expanded {
	position: absolute;
	top: 4px;
	right: -10px;
	width: 16px;
	height: 16px;
	z-index: 1000;
}

.footer {
	grid-area: footer;
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px;
	flex-wrap: wrap;
	img {
		height: 48px;
	}
	a {
		color: var(--mantine-color-orange-6);
		text-decoration: none;
	}
	@media screen and (max-width: 768px) {
		justify-content: center;
	}
}

