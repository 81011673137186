.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	form {
		width: 343px;
	}
}

.title {
	font-size: 34px;
	font-style: normal;
	font-weight: 700;
	line-height: 41px; /* 120.588% */
	letter-spacing: 0.374px;
	margin-top: 42px;
	margin-bottom: 58px;
}